import ReviewModal from './ReviewModal';
import { cases } from './data';

export default class BeforeAfter {
  caseId: number = 0;

  buttons!: HTMLElement[];

  beforeImg!: HTMLImageElement;

  afterImg!: HTMLImageElement;

  caseNumberText!: HTMLElement;

  beforeText!: HTMLElement;

  afterText!: HTMLElement;

  reviewModal!: ReviewModal;

  spinnerElement!: HTMLElement;

  constructor() {
    this.init();
    this.bindEvents();
  }

  init = () => {
    this.buttons = Array.from(document.querySelectorAll('.before-after__img-titles [data-ba-case]')) as HTMLElement[];
    const baSliderElement = document.querySelector('.before-after__slider') as HTMLElement;
    this.beforeImg = baSliderElement.firstElementChild?.firstElementChild as HTMLImageElement;
    this.afterImg = baSliderElement.lastElementChild?.previousElementSibling?.firstElementChild as HTMLImageElement;
    this.spinnerElement = baSliderElement.lastElementChild as HTMLElement;
    const baDescElement = document.querySelector('.before-after__desc') as HTMLElement;
    this.caseNumberText = baDescElement.firstElementChild?.firstElementChild?.firstElementChild as HTMLElement;
    this.beforeText = baDescElement.firstElementChild?.firstElementChild?.lastElementChild as HTMLElement;
    this.afterText = baDescElement.firstElementChild?.lastElementChild?.lastElementChild as HTMLElement;
    this.reviewModal = new ReviewModal('.before-after__review-btn', '.before-after');
  };

  bindEvents = () => {
    window.addEventListener('load', this.removeSpinner);
    this.buttons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        const target = e.target as HTMLElement;
        this.changeCase(Number(target.dataset.baCase));
      });
    });
  };

  removeSpinner = () => {
    this.spinnerElement?.classList.remove('loading');
  };

  addSpinner = () => {
    this.spinnerElement?.classList.add('loading');
    this.beforeImg.addEventListener('load', () => {
      if (this.afterImg.complete) this.removeSpinner();
    });
    this.afterImg.addEventListener('load', () => {
      if (this.beforeImg.complete) this.removeSpinner();
    });
  };

  changeCase = (caseId: number) => {
    this.addSpinner();
    this.buttons[this.caseId].classList.remove('active');
    this.buttons[caseId].classList.add('active');
    this.caseId = caseId;
    this.beforeImg.srcset = cases[caseId].beforeImg + '.jpg 2x';
    this.beforeImg.src = cases[caseId].beforeImg + '-s.jpg';
    this.beforeImg.alt = `случай ${caseId + 1} до`;
    this.afterImg.srcset = cases[caseId].afterImg + '.jpg 2x';
    this.afterImg.src = cases[caseId].afterImg + '-s.jpg';
    this.afterImg.alt = `случай ${caseId + 1} после`;
    this.caseNumberText.innerText = `Случай ${caseId + 1}`;
    this.beforeText.innerText = cases[caseId].beforeText;
    this.afterText.innerText = cases[caseId].afterText;
    this.reviewModal.changeReview(caseId);
  };
}
