export const galleries = [
  [
    {
      link: 'assets/img/clinic-images/barmaleeva/1.jpg',
      title: 'Бармалеева приемная',
    },
    {
      link: 'assets/img/clinic-images/barmaleeva/2.jpg',
      title: 'Бармалеева приемная',
    },
    {
      link: 'assets/img/clinic-images/barmaleeva/3.jpg',
      title: 'Кабинет на Бармалеева',
    },
    {
      link: '/assets/img/clinic-images/barmaleeva/4.jpg',
      title: 'Кабинет на Бармалеева',
    },
    {
      link: '/assets/img/clinic-images/barmaleeva/5.jpg',
      title: 'Детский уголок на Бармалеева',
    },
  ],
  [
    {
      link: '/assets/img/clinic-images/primorski/1.jpg',
      title: 'Приморский приемная',
    },
    {
      link: '/assets/img/clinic-images/primorski/2.jpg',
      title: 'Кабинет на Приморском',
    },
    {
      link: '/assets/img/clinic-images/primorski/3.jpg',
      title: 'Стойка на Приморском',
    },
    {
      link: '/assets/img/clinic-images/primorski/4.jpg',
      title: 'Кабинет на Приморском',
    },
    {
      link: '/assets/img/clinic-images/primorski/5.jpg',
      title: 'Кабинет с установкой на Приморском',
    },
  ],
];

// first element in array corresponds to the first gallery array element, second - to the second and so on
export const mapLinks = [
  [
    'https://yandex.ru/profile/216906433727',
    'https://go.2gis.com/c3w7u',
    'https://maps.app.goo.gl/pVCCGyHrN2oqfogH6',
    'https://prodoctorov.ru/spb/lpu/62740-palkin/',
  ],
  [
    'https://yandex.ru/profile/1064354257',
    'https://go.2gis.com/pdf95',
    'https://maps.app.goo.gl/677WoXCNwLRcqVdz9',
    'https://prodoctorov.ru/spb/lpu/62738-palkin/',
  ],
];
