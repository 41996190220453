export default class BurgerMenu {
  burgerBtn!: HTMLElement;

  burgerMenuCloseBtn!: HTMLElement;

  burgerMenu!: HTMLElement;

  burgerMenuBtn!: HTMLElement;

  burgerMenuLinks!: HTMLElement;

  constructor() {
    this.init();
  }

  init = () => {
    this.burgerBtn = document.querySelector('.burger-btn') as HTMLElement;
    this.burgerMenu = document.querySelector('.burger-menu') as HTMLElement;
    this.burgerMenuBtn = this.burgerMenu?.firstElementChild?.firstElementChild as HTMLElement;
    this.burgerMenuLinks = this.burgerMenuBtn.nextElementSibling?.firstElementChild?.firstElementChild as HTMLElement;
    this.burgerMenuCloseBtn = document.querySelector('.burger-menu__close-btn') as HTMLElement;
  };

  openMenu = () => {
    this.burgerMenu.classList.add('open');
    this.burgerBtn.classList.add('open');

    if (!document.body.classList.contains('body-no-scroll')) {
      const scrollPos = window.scrollY;
      document.body.style.top = `-${scrollPos}px`;
      document.body.dataset.scrollPos = `${scrollPos}`;
    }
    document.body.classList.add('body-no-scroll', 'menu-open');
  };

  closeMenu = () => {
    this.burgerMenu.classList.remove('open');
    this.burgerBtn.classList.remove('open');
    document.body.classList.remove('body-no-scroll', 'menu-open');
    document.body.style.removeProperty('top');
    window.scrollTo({
      top: Number(document.body.dataset.scrollPos),
      left: 100,
      behavior: 'instant',
    });
    document.body.dataset.scrollPos = '';
  };

  handleClickOutside = (event: MouseEvent) => {
    if (
      this.burgerMenu.classList.contains('open') &&
      !this.burgerMenu.contains(event.target as Node) &&
      !this.burgerBtn.contains(event.target as Node)
    ) {
      this.closeMenu();
    }
  };

  bindEvents = () => {
    this.burgerBtn.addEventListener('click', this.openMenu);
    this.burgerMenuBtn.addEventListener('click', this.closeMenu);
    this.burgerMenuCloseBtn.addEventListener('click', this.closeMenu);

    this.burgerMenuLinks.addEventListener('click', (e: MouseEvent | TouchEvent) => {
      const target = e.target as HTMLElement;
      if (target.tagName == 'A') {
        this.closeMenu();
      }
    });
    document.addEventListener('click', this.handleClickOutside);
  };

  run = () => {
    this.bindEvents();
  };
}
