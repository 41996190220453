export const cases = [
  {
    beforeImg: 'assets/img/before-after/0b',
    afterImg: 'assets/img/before-after/0a',
    beforeText: 'Не нравилась форма и цвет зубов, скалывались отдельные коронки в одних и тех же местах.',
    afterText:
      'Подобрали новую форму зубов с помощью воскового макета будущей улыбки. Бережно сняли все старые виниры и коронки. Зафиксировали новые реставрации (виниры и циркониевые коронки на импланты в жевательных отделах). Длительность лечения: 3 месяца.',
    name: 'Андрей',
    service: 'Восстановление эстетики при помощи виниров Emax и протезирование на имплантах',
    doctor: 'Валиев Рафик Тенгизович',
    review:
      'Увидел красивые зубы у своей подруги и захотел также. Беспокоил внешний вид виниров и коронок, которые установил несколько лет назад. Хотел "обновить" зубы, сделать улыбку более эстетичной. Доктор предложил несколько вариантов, подробно рассказал в чем отличие каждого из них, показал примеры работ, поэтому мы быстро приступили к реализации. Клиника работает с лучшими техниками города. Улыбаюсь во все "32" и рекомендую!',
    avatar: 'assets/img/reviews/0avatar',
  },
  {
    beforeImg: 'assets/img/before-after/1b',
    afterImg: 'assets/img/before-after/1a',
    beforeText:
      'Отсутствие передних зубов, старые коронки, под которые забивалась пища, запах изо рта, кровоточивость десен, множественные кисты на корнях зубов под коронками.',
    afterText:
      'Перелечили все кисты, сохранили свои зубы. Установили импланты в переднем отделе верхней челюсти. Зафиксировали постоянные керамические реставрации и коронки на импланты. Длительность лечения: 4 месяца.',
    name: 'Наталья',
    service: 'Имплантация и протезирование зубов',
    doctor: 'Лубкин Павел Викторович, Нагайко Александр Евгеньевич',
    review:
      'Пишу отзыв спустя 3 года. Я очень благодарна всем докторам клиники "Палкинъ", кто со мной работал. Мне сохранили все мои зубы и поменяли старые коронки. Улыбаюсь сейчас постоянно абсолютно не стесняясь, все друзья и коллеги это отмечают. У меня даже разгладились морщинки вокруг губ, потому что вернули мне высоту зубов. Хожу на осмотр раз в полгода на чистку и спустя 3 года больше ничего не лечила - всё хорошо.',

    avatar: 'assets/img/reviews/1avatar',
  },
  {
    beforeImg: 'assets/img/before-after/2b',
    afterImg: 'assets/img/before-after/2a',
    beforeText: 'Подвижность всех зубов (пародонтит 3 степени), отсутствие передних зубов, не нравилась улыбка.',
    afterText:
      'Изготовили навигационный шаблон для операции. Удалили все зубы с высокой подвижностью. Поставили 4 импланта на верхнюю челюсть и 4 импланта на нижнюю челюсть. Без костной пластики + адаптационный протез. Заменили протезы на коронки с фиксацией на имплантах. Длительность лечения: 16 месяцев.',
    name: 'Ольга',
    service: 'Имплантация зубов All-on-4',
    doctor: 'Лубкин Павел Викторович, Нагайцев Сергей Леонидович',
    review:
      'Клинику "Палкин" выбирала по отзыву знакомой 2 года назад. Администраторы помогали на каждом шагу. Есть стоянка, запись под клиента. Чуткий, скрупулёзно подходящий к проблемам доктор Лубкин Павел Викторович и его ассистентка Валерия. Мария - мой куратор расписала план лечения, возможные варианты, цены и прочее. В клинике мне удалили зубы и поставили имплантаты. После удаления даже почти не было опухоли. Очень благодарна клинике за свою улыбку! Спасибо огромное!',
    avatar: 'assets/img/reviews/2avatar',
  },
  {
    beforeImg: 'assets/img/before-after/3b',
    afterImg: 'assets/img/before-after/3a',
    beforeText:
      'Отсутствовали зубы, у пациента старые съемные протезы, которые не фиксировались во рту, мешали дикции и приему пищи.',
    afterText:
      'После диагностики изготовили навигационный хирургический шаблон. Установили 8 имплантов по шаблону (без костных пластик): 4 импланта наверху и 4 импланта внизу. Зафиксировали адаптационный протез. Через 6 месяцев установили постоянные условно-съемные акриловые протезы с фиксацией на имплантах. Длительность лечения: 11 месяцев.',
    name: 'Владимир',
    service: 'Восстановление зубов с помощью имплантации All-on-4',
    doctor: 'Валиев Рафик Тенгизович, Нагайко Александр Евгеньевич',
    review:
      'Сделано хорошо, зубы отличные. Жевать прекрасно, Стоят на месте (смеется). Все отлично, честно, очень доволен. Никак не ощущается, почти как родные (немного отличаются, конечно). Самое главное - удобно есть и жевать, вот правда - самое главное. Доктора молодцы! С вами очень приятно общаться, рад звонкам!',
    avatar: 'assets/img/reviews/3avatar',
  },
  {
    beforeImg: 'assets/img/before-after/4b',
    afterImg: 'assets/img/before-after/4a',
    beforeText: 'Отсутствие жевательных зубов, старые пломбы, клиновидные дефекты, недовольство внешним видом зубов.',
    afterText:
      'Установили импланты в задние отделы зубов. Вылечили все кариозные поражения зубов. Зафиксировали керамические реставрации и коронки на импланты. Длительность лечения: 11 месяцев.',
    name: 'Ольга',
    service: 'Восстановление улыбки с помощью имплантации зубов и керамических реставраций',
    doctor: 'Лубкин Павел Викторович, Нагайко Александр Евгеньевич',
    review:
      'Давно являюсь клиентом стоматологии "Палкинъ" и чаще всего посещаю клинику на Приморском проспекте. Очень рекомендую Нагайко Александра Евгеньевича и Лубкина Павла Викторовича! Каждый день смотрю утром в зеркало и радуюсь, потому что закончила лечение и поставила виниры. Жую всеми зубами. Прекрасные специалисты и хорошие люди! На чистку и осмотр хожу к разным врачам - все хорошие и доверяю полностью! ',
    avatar: 'assets/img/reviews/4avatar',
  },
];
