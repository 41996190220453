import ElementTemplate from '../ElementTemplate';
import { cases } from './data';

export class ReviewElement extends ElementTemplate {
  closeBtn!: ElementTemplate<HTMLButtonElement>;

  constructor(reviewId: number = 0) {
    super(null, 'div', 'before-after__review review');
    this.createCloseBtn();
    this.setupReview(reviewId);
  }

  setupReview = (reviewId: number) => {
    this.node.innerHTML = `
        <img srcset="assets/img/before-after/${reviewId}avatar-b.jpg 2x" src="assets/img/before-after/${reviewId}avatar.jpg" class="review__photo" alt="аватар пациента">
    `;
    this.createReviewContainer(reviewId);
  };

  createCloseBtn = () => {
    this.closeBtn = new ElementTemplate<HTMLButtonElement>(this.node, 'button', 'review__close-btn');
    this.closeBtn.node.type = 'button';
    this.closeBtn.node.innerHTML = `
     <span class="review__close-text">закрыть</span>
        <svg class="review__close-btn-svg" width="169" height="8">
         <use href="assets/sprite.svg#close-arrow" x="0" y="0"></use>
        </svg>
    `;
  };

  createReviewContainer = (reviewId: number) => {
    const review = cases[reviewId];
    const reviewContent = `
        <h4 class="review__title">${review.name}</h4>
        <div class="review__data-container">
            <div class="review__data"><span class="review__data-title">Услуга: </span><span class="review__data-desc">${review.service}</span></div>
            <div class="review__data"><span class="review__data-title">Врач: </span><span class="review__data-desc">${review.doctor}</span></div>
         </div>
        <p class="review__review">${review.review}</p>
    `;
    const reviewDataContainer = new ElementTemplate(this.node, 'div', 'review__container', reviewContent);
    reviewDataContainer.node.append(this.closeBtn.node);
  };

  addListeners = () => {
    this.node.lastElementChild?.lastElementChild?.addEventListener('click', () => {
      this.node.remove();
    });
  };
}
