export default class FaqAccordion {
  questions: (HTMLElement | null)[] = [];

  answers: (HTMLElement | null)[] = [];

  constructor() {
    this.init();
  }

  init() {
    const questionsContainers = document.querySelectorAll('.faq__question-container');
    this.questions = Array.from(questionsContainers).map(
      (container) => (container.firstElementChild as HTMLElement) ?? null,
    );
    this.answers = Array.from(questionsContainers).map(
      (container) => (container.lastElementChild as HTMLElement) ?? null,
    );
  }

  toggleAnswer(index: number) {
    const answer = this.answers[index];
    if (answer) {
      answer.classList.toggle('active');
      if (answer.classList.contains('active')) answer.style.maxHeight = `${answer.scrollHeight + 15}px`;
      else {
        answer.style.maxHeight = '';
      }
    }
  }

  toggleQuestion(index: number) {
    const question = this.questions[index];
    if (question) {
      question.classList.toggle('active');
    }
  }

  bindEvents() {
    this.questions.forEach((question, index) => {
      question?.addEventListener('click', () => {
        this.toggleAnswer(index);
        this.toggleQuestion(index);
      });
    });
  }

  run() {
    this.bindEvents();
  }
}
