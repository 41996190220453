import './sass/style.scss';
import FaqAccordion from './scripts/FaqAccordion';
import BurgerMenu from './scripts/BurgerMenu';
import CardModal from './scripts/CardModal';
// import ReviewModal from './scripts/BeforeAfter/ReviewModal';
import CarouselGallery from './scripts/CarouselGallery/CarouselGallery';
import BaSlider from './scripts/BaSlider';
import BeforeAfter from './scripts/BeforeAfter/BeforeAfter';

const faqAccordion = new FaqAccordion();
faqAccordion.run();
const burgerMenu = new BurgerMenu();
burgerMenu.run();
new CardModal('.doctor-card');
// new ReviewModal('.before-after__review-btn', '.before-after__review');

const carouselGallery = new CarouselGallery();
carouselGallery.run();
new BeforeAfter();
new BaSlider('.before-after__slider');
