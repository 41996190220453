import { galleries } from './data';
import { mapLinks } from './data';

export default class CarouselGallery {
  galleryId: number;

  images: { link: string; title: string }[];

  carouselContainerEl!: HTMLElement;

  bigImgEl: HTMLImageElement | null = null;

  mapLinks: HTMLAnchorElement[][] = [];

  imgDescEl: HTMLImageElement | null = null;

  imagesElements: HTMLImageElement[] = [];

  imageActive!: HTMLImageElement;

  addressesElements: (HTMLElement | null)[] = [];

  isChanging = false;

  constructor() {
    this.galleryId = 0;
    this.images = galleries[0];
    this.init();
  }

  init = () => {
    this.bigImgEl = document.querySelector('[data-gallery-img-big]');
    this.mapLinks = Array.from(document.querySelectorAll('[data-map-links]')).map(
      (element) => Array.from(element.children) as HTMLAnchorElement[],
    );

    this.imgDescEl = document.querySelector('[data-gallery-img-desc]');
    this.imagesElements = Array.from(
      document.querySelectorAll('[data-gallery-carousel] [data-gallery-img-id]'),
    ) as HTMLImageElement[];
    this.carouselContainerEl = this.imagesElements[0].parentElement?.parentElement as HTMLElement;
    this.imageActive = this.imagesElements[0].parentElement as HTMLImageElement;
    this.addressesElements = Array.from(document.querySelectorAll('[data-gallery-id]')) as (HTMLElement | null)[];
  };

  bindEvents = () => {
    if (this.bigImgEl?.complete) this.removeSpinner();
    this.bigImgEl?.addEventListener('load', () => {
      this.bigImgEl?.nextElementSibling?.classList.remove('loading');
      this.bigImgEl?.nextElementSibling?.nextElementSibling?.classList.remove('loading');
    });
    this.imagesElements.forEach((element) => {
      if (element.complete) this.removeSpinner(element);
      element?.addEventListener('load', () => {
        element.nextElementSibling?.classList.remove('loading');
      });
      element?.addEventListener('click', (e) => {
        e.preventDefault();
        const target = e.target as HTMLImageElement;
        this.imageActive.classList.remove('active');
        this.imageActive = target.parentElement as HTMLImageElement;
        this.imageActive.classList.add('active');
        this.changeBigImg(Number(target.dataset.galleryImgId));
      });
    });
    this.addressesElements.forEach((element) => {
      element?.addEventListener('click', (e) => {
        e.preventDefault();
        const target = e.currentTarget as HTMLElement;
        this.changeGallery(Number(target.dataset.galleryId));
      });
    });
  };

  removeSpinner = (element: HTMLImageElement | null = null) => {
    if (!element) {
      this.bigImgEl?.nextElementSibling?.classList.remove('loading');
      this.bigImgEl?.nextElementSibling?.nextElementSibling?.classList.remove('loading');
    } else {
      element.nextElementSibling?.classList.remove('loading');
    }
  };

  changeBigImg = (id: number) => {
    if (this.isChanging) return;
    this.isChanging = true;
    this.bigImgEl!.classList.add('fade-out');
    this.imgDescEl!.classList.add('fade-out');

    setTimeout(() => {
      this.bigImgEl!.src = this.images[id].link;
      this.bigImgEl!.alt = this.images[id].title;
      this.imgDescEl!.innerText = this.images[id].title;
      this.isChanging = false;
      this.bigImgEl!.classList.remove('fade-out');
      this.imgDescEl!.classList.remove('fade-out');
    }, 100);
  };

  changeGallery = (id: number) => {
    this.galleryId = id;
    this.images = galleries[id];
    this.addressesElements.forEach((element) =>
      element?.dataset.galleryId === String(id) ? element.classList.add('active') : element?.classList.remove('active'),
    );
    this.imagesElements.forEach((element, index) => {
      if (element) {
        element.dataset.galleryImgId = String(index);
        element.src = this.images[index].link;
        element.alt = this.images[index].title;
        element.nextElementSibling?.classList.add('loading');
      }
    });
    this.imageActive.classList.remove('active');
    this.imageActive = this.imagesElements[0].parentElement as HTMLImageElement;
    this.imageActive.classList.add('active');
    this.carouselContainerEl.scrollLeft = 0;
    this.changeBigImg(0);
    this.bigImgEl?.nextElementSibling?.classList.add('loading');
    this.bigImgEl?.nextElementSibling?.nextElementSibling?.classList.add('loading');
    this.mapLinks.forEach((mapLinksArr) => {
      mapLinksArr.forEach((link, index) => (link.href = mapLinks[id][index]));
    });
  };

  run = () => {
    this.bindEvents();
  };
}
