export default class BeforeAfter {
  beforeElement: HTMLElement;

  slider: HTMLElement;

  width = 0;

  sliderWidth = 0;

  height = 0;

  clicked = false;

  constructor(sliderElement: string) {
    this.beforeElement = document.querySelector(`${sliderElement}`)?.firstElementChild as HTMLElement;
    this.slider = this.beforeElement.nextElementSibling as HTMLElement;
    window.addEventListener('load', this.init);
  }

  init = () => {
    // const a = this.afterElement.getBoundingClientRect();
    this.width = this.beforeElement.offsetWidth;
    this.height = this.beforeElement.offsetHeight;
    this.beforeElement.style.width = this.width / 4 + 'px';
    (this.beforeElement.firstElementChild as HTMLElement).style.width = this.width + 'px';
    this.sliderInit();
  };

  sliderInit = () => {
    this.slider.style.display = 'flex';
    this.sliderWidth = this.slider.offsetWidth;
    this.slider.style.top = (this.height / 4) * 3 - this.slider.offsetHeight / 2 + 'px';
    this.slider.style.right = this.width - (this.width / 4 + this.sliderWidth / 2 - 1) + 'px';
    this.slider.addEventListener('mousedown', this.slideReady);
    window.addEventListener('mouseup', this.slideFinish);
    this.slider.addEventListener('touchstart', this.slideReady);
    window.addEventListener('touchend', this.slideFinish);
  };

  slideReady = (e: MouseEvent | TouchEvent) => {
    e.preventDefault();
    this.clicked = true;
    this.slider.classList.add('active');
    window.addEventListener('mousemove', this.slideMove);
    window.addEventListener('touchmove', this.slideMove);
  };

  slideFinish = () => {
    this.clicked = false;
    this.slider.classList.remove('active');
  };

  slideMove = (e: MouseEvent | TouchEvent) => {
    if (this.clicked) {
      this.slide(this.getCursorPosition(e));
    } else return false;
  };

  getCursorPosition = (e: MouseEvent | TouchEvent) => {
    const beforeElementData = this.beforeElement.getBoundingClientRect();

    let positionX;
    if (e instanceof MouseEvent) positionX = e.pageX - beforeElementData.left;
    else positionX = e.touches[0].pageX - beforeElementData.left;
    positionX = positionX - window.scrollX;
    if (positionX < 0) positionX = 0;
    if (positionX > this.width) positionX = this.width;
    return positionX;
  };

  slide = (cursorPosition: number) => {
    this.beforeElement.style.width = cursorPosition + 'px';
    this.slider!.style.right = this.width - this.beforeElement.offsetWidth - this.sliderWidth / 2 + 1 + 'px';
  };
}
