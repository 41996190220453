export default class CardModal {
  cards: HTMLElement[] = [];

  answers: (HTMLElement | null)[] = [];

  constructor(cardClass: string) {
    this.cards = Array.from(document.querySelectorAll(cardClass)) as HTMLElement[];
    this.bindEvents();
  }

  bindEvents = () => {
    this.cards.forEach((card) => {
      card.addEventListener('click', this.toggleModal);
    });
  };

  toggleModal = (e: MouseEvent) => {
    e.preventDefault();
    const target = e.currentTarget as HTMLElement;
    target.classList.toggle('active');
  };
}
