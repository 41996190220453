import ElementTemplate from '../ElementTemplate';
import { ReviewElement } from './ReviewElement';

export default class ReviewModal {
  parentSection: HTMLElement;

  btn: HTMLButtonElement;

  modalWindow: ReviewElement;

  closeBtn: ElementTemplate<HTMLButtonElement>;

  constructor(btnClass: string, parentSectionClass: string) {
    this.parentSection = document.querySelector(parentSectionClass) as HTMLElement;
    this.btn = document.querySelector(btnClass) as HTMLButtonElement;
    this.modalWindow = new ReviewElement();
    // this.modalWindow = document.querySelector(windowClass) as HTMLElement;
    this.closeBtn = this.modalWindow.closeBtn;
    this.parentSection.append(this.modalWindow.node);
    // this.closeBtn = this.modalWindow.lastElementChild?.lastElementChild as HTMLButtonElement;
    this.btn.addEventListener('click', this.openModal);
  }

  clickOutsideHandler = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.modalWindow.node.contains(e.target as Element)) {
      document.removeEventListener('click', this.clickOutsideHandler);
      this.closeModal();
    }
  };

  openModal = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.closeBtn.node.addEventListener('click', this.closeModal);
    this.modalWindow.node.classList.add('active');
    this.btn.removeEventListener('click', this.openModal);
    const scrollPos = window.scrollY;
    document.body.dataset.scrollPos = `${scrollPos}`;
    document.body.classList.add('body-no-scroll');
    document.body.style.top = `-${scrollPos}px`;
    document.addEventListener('click', this.clickOutsideHandler);
  };

  closeModal = () => {
    this.modalWindow.node.classList.remove('active');
    this.btn.addEventListener('click', this.openModal);
    if (document.body.classList.contains('menu-open')) return;
    document.body.classList.remove('body-no-scroll');
    document.body.style.removeProperty('top');
    window.scrollTo({
      top: Number(document.body.dataset.scrollPos),
      left: 100,
      behavior: 'instant',
    });
    this.closeBtn.node.removeEventListener('click', this.closeModal);
    document.removeEventListener('click', this.clickOutsideHandler);
  };

  changeReview = (reviewId: number) => {
    this.modalWindow.setupReview(reviewId);
  };
}
